<template>
    <v-container>
        <vue-draggable-resizable :h="600" :w="350">
            <BT-Blade title="Menu" :canRefresh="false" hideBackButton>
                <template>
                    <v-expansion-panels>

                        <BT-Blade-Expansion-Items
                            :canExportCSV="false"
                            :canRefresh="false"
                            :dividers="false"
                            :headers="[{ text: 'Name', value: 'groupName', title: 1 }]"
                            :itemProperties="['ID','GroupName']"
                            navigation="product-groups"
                            :onSelect="(b, item) => { openProductGroupBox(item) }"
                            showList
                            title="Product Groups" />

                    </v-expansion-panels>
                </template>
            </BT-Blade>
        </vue-draggable-resizable>

        <BT-Board-Drop-List
            v-for="(block, index) in blocks"
            @close="closeDropBox"
            @removeLink="removeLink"
            @select="selectBox"
            :key="'d' + index"
            :item="block.item"
            :itemProperties="block.itemProperties"
            :linkNavigation="block.linkNavigation"
            :linkGetURL="block.linkGetURL"
            :linkParams="block.linkParams"
            :linkRemoveByDelete="block.linkRemoveByDelete"
            :manyNavigation="block.manyNavigation"
            :manyProp="block.manyProp"
            :manyText="block.manyText"
            :manyToMany="block.manyToMany"
            :searchProps="block.searchProps"
            :singleID="block.singleID"
            :singleProp="block.singleProp"
            :sortProp="block.sortProp"
            :titleProp="block.titleProp" />

        <BT-Board-Supply-List
            v-for="(block, index) in supplyBlocks"
            @close="closeSupplyBox"
            :canInsert="block.canInsert"
            :key="'s' + index"
            :itemProperties="block.itemProperties"
            :linkNavigation="block.linkNavigation"
            :linkGetURL="block.linkGetURL"
            :linkParams="block.linkParams"
            :linkRemoveByDelete="block.linkRemoveByDelete"
            :linkRemoveToggle="block.linkRemoveToggle"
            :manyNavigation="block.manyNavigation"
            :manyProp="block.manyProp"
            :manyText="block.manyText"
            :manyToMany="block.manyToMany"
            :searchProps="block.searchProps"
            :singleID="block.singleID"
            :singleProp="block.singleProp"
            :sortProp="block.sortProp" />

    </v-container>
</template>

<script>
export default {
    name: 'Product-Board',
    components: {
        BTBoardDropList: () => import('~components/BT-Board-Drop-List.vue'),
        BTBoardSupplyList: () => import('~components/BT-Board-Supply-List.vue'),
        VueDraggableResizable: () => import('vue-draggable-resizable')
    },
    data: function() {
        return {
            blocks: [],
            supplyBlocks: [],
        }
    },
    methods: {
        closeDropBox({ id }) {
            var ind = this.blocks.findIndex(x => x.item.id == id);
            if (ind > -1) {
                var nav = this.blocks[ind].navigation;
                this.blocks.splice(ind, 1);

                if (!this.blocks.some(y => y.navigation == nav)) {
                    this.closeSupplyBox({ navigation: nav });
                }
            }
        },
        closeSupplyBox({ navigation }) {
            var ind = this.supplyBlocks.findIndex(x => x.manyNavigation == navigation);
            if (ind > -1) {
                this.supplyBlocks.splice(ind, 1);

                if (this.blocks.some(y => y.manyNavigation == navigation)) {
                    this.blocks = this.blocks.filter(x => x.manyNavigation != navigation);
                }
            }
        },

        openBox(
            item,
            itemProperties = null,
            linkGetURL = null,
            linkNavigation = null,
            manyNavigation = 'customers',
            manyProp = null,
            manyText = null,
            manyToMany = null,
            searchProps = null,
            singleProp = null,
            sortProp = 'buyer.companyName',
            titleProp = 'groupName') {
            
            this.blocks = this.blocks.filter(x => x.singleProp == singleProp && x.manyNavigation == manyNavigation);
            
            if (!this.blocks.some(y => y.item.id == item.id)) {
                this.blocks.push({
                    item,
                    itemProperties,
                    linkGetURL,
                    linkNavigation,
                    manyNavigation,
                    manyProp,
                    manyText,
                    manyToMany,
                    searchProps,
                    singleProp,
                    sortProp,
                    titleProp
                });
            }

            var supplyBlock = this.supplyBlocks.find(y => y.manyNavigation == manyNavigation);
            if (supplyBlock == null) {
                this.supplyBlocks.push({
                    canInsert: true,
                    itemProperties,
                    linkGetURL,
                    linkNavigation,
                    linkRemoveToggle: null,
                    manyNavigation,
                    manyProp,
                    manyText,
                    manyToMany,
                    searchProps,
                    singleID: item.id,
                    singleProp,
                    sortProp,
                    title: 'Customers'
                })
            }
            else {
                supplyBlock.linkNavigation = linkNavigation;
                supplyBlock.linkGetURL = linkGetURL;
                supplyBlock.manyNavigation = manyNavigation;
                supplyBlock.manyProp = manyProp;
                supplyBlock.manyText = manyText;
                supplyBlock.manyToMany = manyToMany;
                supplyBlock.singleProp = singleProp;
                supplyBlock.singleID = item.id;
            }

            this.selectBox({
                linkNavigation,
                linkGetURL,
                manyNavigation,
                manyProp,
                manyText,
                manyToMany,
                singleID: item.id,
                singleProp
            });
        },
        openProductGroupBox(item) {
            this.openBox(
                item,
                ['ID','ProductName'],
                '/get/Links?groupID={id}',
                'product-groups',
                'products',
                'productID',
                'productName',
                true,
                ['productName'],
                'productOfferingGroupID',
                'productName',
                'groupName')
        },

        removeLink({ manyNavigation, manyID, singleID }) {
            console.log('removing link');
            var sBox = this.supplyBlocks.find(y => y.manyNavigation == manyNavigation);
            if (sBox != null) {
                console.log('sbox found');
                sBox.linkRemoveToggle = { manyID, singleID };
            }
        },
        selectBox(d) { //manyNavigation, manyToMany, singleID, singleProp }) {
            var sBox = this.supplyBlocks.find(y => y.manyNavigation == d.manyNavigation);
            if (sBox != null) {
                console.log(d);
                sBox.linkNavigation = d.linkNavigation;
                sBox.linkGetURL = d.linkGetURL;
                sBox.manyNavigation = d.manyNavigation;
                sBox.manyProp = d.manyProp;
                sBox.manyText = d.manyText;
                sBox.manyToMany = d.manyToMany;
                sBox.singleProp = d.singleProp;
                sBox.singleID = d.singleID;
            }
        }
    }
}
</script>